


























































































































import { API_SETTING_ORG_INFO, API_UPLOAD_ORG_LOGO } from "@/configs/Apis";
import { MSG_INVALID_PAGE, MSG_MODIFY_SUCCESS } from "@/configs/Consts";
import {
  hasPermission,
  isEmptyOrWhiteSpace,
  showError,
  showSuccess,
  showWarning
} from "@/utils/Common";
import { Fetcher as Ajax } from "@/utils/Request";
import { Component, Vue } from "vue-property-decorator";
@Component
export default class OrgInfoSetting extends Vue {
  isEditing = false;
  settingItem: any = {};
  imgUrl = "";
  created() {
    this.loadData();
  }

  loadData() {
    Ajax.queryData(API_SETTING_ORG_INFO, {}, "GET")
      .then(data => {
        this.settingItem = JSON.parse(JSON.stringify(data || {}));
        this.imgUrl = this.settingItem.logo || "";
      })
      .catch(() => "");
  }

  hasPerm(checkedPerm: string) {
    return hasPermission(this.$store.getters["user/user"] || {}, checkedPerm);
  }

  get uploadAction() {
    return Ajax.formatApiUrl(API_UPLOAD_ORG_LOGO, {});
  }

  get showFileList() {
    return isEmptyOrWhiteSpace(this.imgUrl);
  }

  handleBgImgSuccess(res: any, file: any) {
    this.imgUrl = URL.createObjectURL(file.raw);
    this.settingItem.logo = file.response.location;
    showSuccess("上传成功");
  }

  beforeBgImgUpload(file: any) {
    if (!file) {
      return false;
    }
    const match = /\.(.+)$/g.exec(file.name);
    if (!match || !match.length) {
      return false;
    }
    const ext: string = match[match.length - 1];
    if (["JPG", "JPEG", "GIF", "PNG"].indexOf(ext.toUpperCase()) === -1) {
      showWarning("文件只能是图片格式!");
      return false;
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      showWarning("图片大小不能超过 2MB!");
      return false;
    }
    return true;
  }

  editInfo() {
    this.isEditing = true;
  }

  cancelEdit() {
    this.isEditing = false;
    this.loadData();
  }

  submitData() {
    const form = this.$refs.settingForm as any;
    form.validate((isValid: boolean) => {
      if (!isValid) {
        showWarning(MSG_INVALID_PAGE);
        return;
      }
      Ajax.saveData(
        API_SETTING_ORG_INFO,
        JSON.parse(JSON.stringify(this.settingItem)),
        "PUT"
      )
        .then(() => {
          showSuccess(MSG_MODIFY_SUCCESS);
        })
        .catch(({ message }) => showError(message));
    });
  }
}
